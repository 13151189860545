import React, { useEffect, useRef, useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { camelCase } from 'lodash'
import Img from 'gatsby-image/withIEPolyfill'
import { Helmet } from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { rgba } from 'polished'
import { useQueryParam, StringParam } from 'use-query-params'

import {
  Box,
  Button,
  Container,
  ButtonUnstyled,
  Icon,
  LoadingSpinner,
  P,
  Ul,
  H3,
  H1,
  H2,
  Paragraph,
} from '@allied-solutions/affinity'
import {
  ChevronDoubleRight,
  ChevronDown,
} from '@allied-solutions/affinity-react-icons/dist/16px'

import { RightArrow } from '@allied-solutions/affinity-react-icons/dist/24px'

import { theme } from '../theme.js'
import { ArticleGrid, Layout, SiteLayout, Nav } from '../components'
import { useFetchBorrower } from '../hooks'

const StyledH2 = styled(H2)`
  text-wrap: balance;
`

const StyledH1 = styled(H1)`
  text-wrap: balance;
  mark {
    display: inline-block;
    line-height: 0em;
    padding-bottom: 0.5em;
    background-color: ${props => props.theme.colors.green400};
    color: ${props => props.theme.colors.darkBlue};
  }
`

const LeftSide = styled(Box)`
  position: relative;
  text-align: center;

  h1,
  p {
    text-align: center;
  }

  h1 {
    ${props => props.theme.typeStyles.h2}
  }

  ${props => props.theme.mediaQueries.lg} {
    text-align: left;

    h1,
    p {
      text-align: left;
    }

    h1 {
      ${props => props.theme.typeStyles.h1}
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -19px;
    right: 133px;
    width: ${props => props.theme.sizes[4]};
    height: ${props => props.theme.sizes[4]};
    border-radius: 50%;
    background-color: ${props => props.theme.colors.blue200};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 39%;
    left: -59px;
    width: ${props => props.theme.sizes[6]};
    height: ${props => props.theme.sizes[6]};
    border-radius: 50%;
    background-color: ${props => props.theme.colors.blue200};
  }
`

const StyledImg = styled(Img)`
  height: 100%;
  border-bottom-left-radius: ${props => props.theme.space[48]};
  display: none;

  ${props => props.theme.mediaQueries.lg} {
    display: block;
  }
`

const RightSide = styled(Box)`
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 263px;
    left: -12px;
    width: ${props => props.theme.sizes[6]};
    height: ${props => props.theme.sizes[6]};
    border-radius: 50%;
    background-color: ${props => props.theme.colors.green600};
    z-index: 2;
  }
`

const AboutBox = styled(Box)`
  &:before {
    content: '';
    display: block;
    width: ${props => props.theme.sizes[30]};
    height: ${props => props.theme.sizes[15]};
    border-top-left-radius: ${props => props.theme.radii.full};
    border-top-right-radius: ${props => props.theme.radii.full};
    background-color: ${props => props.theme.colors.green600};
    position: absolute;
    top: 0;
    left: -${props => props.theme.space[22]};

    ${props => props.theme.mediaQueries.md} {
      left: 0;
    }
  }
`

const includedFaqs = [
  `Why did I get this letter in the mail?`,
  `How long does insurance verification take?`,
  `How do I know if I have the right coverage?`,
  `Who is MyInsuranceInfo?`,
]

const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    let A = a[key],
      B = b[key]

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    }
    return -1
  })

  return array
}

const Home = ({
  data: {
    file: { childImageSharp },
    mdx: { frontmatter },
    faqs,
  },
}) => {
  const refId = useQueryParam('id', StringParam)[0] || ''
  const scrollToRef = useRef(0)

  const [isFetchingBorrower, setIsFetchingBorrower] = useState(false)

  const { getBorrower } = useFetchBorrower(
    '/enter-reference-id/confirm-identity/'
  )

  const fetchBorrowerFromQueryString = async refId => {
    // console.log(refId)
    if (!refId) return

    setIsFetchingBorrower(true)
    await getBorrower(refId).catch(error => {
      console.error(error)
      navigate('/systems-down/')
    })

    setIsFetchingBorrower(false)
  }

  useEffect(() => {
    fetchBorrowerFromQueryString(refId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refId])

  return (
    <>
      <Layout>
        <Helmet>
          <title>{frontmatter.metaTitle}</title>
          <meta name="description" content={frontmatter.metaDescription} />
        </Helmet>
        <SiteLayout>
          <Box
            height="100px"
            width="100%"
            position="absolute"
            zIndex="100"
            css={`
              ${props => props.theme.mediaQueries.lg} {
                background-color: white;
              }
              @media only screen and (min-width: 1380px) {
                background-color: transparent;
              }
            `}
          >
            <Nav underlineHome />
          </Box>

          {/* hero */}
          <Box>
            <Box
              bg="lightBlue200"
              position="relative"
              borderBottomLeftRadius={['120px', '120px', '120px', 0]}
              overflow={['hidden', 'hidden', 'hidden', 'visible']}
            >
              <Container px={8} pt={40} pb={[8, 8, 8, 18]}>
                <Box display="flex">
                  <LeftSide width={[null, null, null, 1 / 2]}>
                    <StyledH1
                      dangerouslySetInnerHTML={{
                        __html: frontmatter.hero.headline,
                      }}
                    />
                    {frontmatter.hero.blurb && (
                      <Paragraph
                        style={{ marginTop: '2rem' }}
                        // mt={8}
                        mb={0}
                        typeStyle="bodyLarge"
                        textAlign="left"
                      >
                        {frontmatter.hero.blurb}
                      </Paragraph>
                    )}
                    {frontmatter.hero.buttonText &&
                      frontmatter.hero.buttonLink && (
                        <Button
                          id={`Home__Hero__Button--${camelCase(
                            frontmatter.hero.buttonText
                          )}`}
                          variant="green"
                          rounded
                          boxShadow="green"
                          size="lg"
                          forwardedAs={Link}
                          to={frontmatter.hero.buttonLink}
                          mt={[8, 8, 8, 20]}
                          css={`
                            padding-left: ${props => props.theme.space[8]};
                            padding-right: ${props => props.theme.space[8]};
                          `}
                        >
                          <P
                            mb={0}
                            typeStyle="labelLarge"
                            textAlign="center"
                            color="white"
                          >
                            {frontmatter.hero.buttonText}
                          </P>
                          <Icon src={RightArrow} ml={2} fontSize="24px" />
                        </Button>
                      )}
                  </LeftSide>
                  <RightSide
                    width={[null, null, null, 5 / 12]}
                    position="absolute"
                    top={0}
                    right={0}
                    bottom={0}
                    mb={-20}
                  >
                    <StyledImg
                      fluid={childImageSharp.fluid}
                      alt=""
                      loading="eager"
                      backgroundColor={theme.colors.lightBlue400}
                    />
                  </RightSide>
                </Box>
              </Container>
            </Box>
            <Box textAlign={['center', 'center', 'center', 'left']}>
              <Container px={8} py={6}>
                <ButtonUnstyled
                  id="Home__Hero__Button--scrollDown"
                  display="inline-flex"
                  alignItems="center"
                  onClick={() => {
                    window.scroll({
                      top: scrollToRef.current.offsetTop - 77,
                      behavior: 'smooth',
                    })
                  }}
                >
                  <Icon src={ChevronDown} color="gray700" mr={1} />
                  <P
                    mb={0}
                    typeStyle="bodyMedium"
                    textAlign="left"
                    color="gray600"
                  >
                    Scroll to learn more
                  </P>
                </ButtonUnstyled>
              </Container>
            </Box>
          </Box>

          {/* about */}
          <Container px={8} py={36} ref={scrollToRef}>
            <AboutBox position="relative" pb={40}>
              <Box pt={26} ml={[null, null, 48]} width={[null, null, 1 / 2]}>
                <StyledH2 textWrap="balance" typeStyle={'h2'}>
                  {frontmatter.about.headline}
                </StyledH2>
                <Paragraph
                  mt={9}
                  style={{ fontSize: '20px', lineHeight: '32px' }}
                  typeStyle="displaySmall"
                  textAlign="left"
                >
                  {frontmatter.about.blurb}
                </Paragraph>
                {frontmatter.about.disclaimer && (
                  <Paragraph
                    mt={10}
                    mb={0}
                    style={{ fontSize: '20px', lineHeight: '32px' }}
                    typeStyle="displaySmall"
                    textAlign="left"
                  >
                    <Icon
                      src={ChevronDoubleRight}
                      display="inline-block"
                      verticalAlign="middle"
                    />
                    {frontmatter.about.disclaimer}
                  </Paragraph>
                )}
              </Box>
            </AboutBox>
          </Container>

          {/* support */}
          <Box
            borderTopRightRadius={40}
            pt="1px"
            pb={48}
            css={`
              background-color: ${props =>
                rgba(props.theme.colors.lightBlue200, 0.4)};
            `}
          >
            <Container px={8}>
              <ArticleGrid mt={-40} mb={40} />
              <Box display={[null, null, null, 'flex']}>
                <Box width={[null, null, null, 1 / 3]}>
                  <H2 typeStyle={['h3', 'h2']}>
                    {frontmatter.support.headline}
                  </H2>
                  {frontmatter.support.buttonText &&
                    frontmatter.support.buttonLink && (
                      <Button
                        aria-label="See all F A Q's"
                        id={`Home__SupportSection__Button--${camelCase(
                          frontmatter.support.buttonText
                        )}`}
                        forwardedAs={Link}
                        to={frontmatter.support.buttonLink}
                        rounded
                        boxShadow="blue"
                        size="lg"
                        mt={15}
                        mb={20}
                        css={`
                          padding-left: ${props => props.theme.space[8]};
                          padding-right: ${props => props.theme.space[8]};
                        `}
                      >
                        <P
                          mb={0}
                          typeStyle="labelLarge"
                          textAlign="center"
                          color="white"
                        >
                          {frontmatter.support.buttonText}
                        </P>
                      </Button>
                    )}
                </Box>
                <Box width={[null, null, null, 2 / 3]}>
                  <Ul
                    css={`
                      li + li {
                        margin-top: ${props => props.theme.space[10]};
                      }
                    `}
                  >
                    {mapOrder(
                      faqs.frontmatter.FAQs.filter(faq =>
                        includedFaqs.includes(faq.question)
                      ),
                      includedFaqs,
                      'question'
                    ).map(faq => (
                      <li key={faq.question}>
                        <H3 fontWeight={900} typeStyle="titleSmall">
                          {faq.question}
                        </H3>

                        <MDXProvider
                          components={{
                            p: props => (
                              <Paragraph
                                typeStyle="bodyLarge"
                                textAlign="left"
                                mb={0}
                                css={`
                                  a {
                                    text-decoration: underline;

                                    &:hover,
                                    &:focus {
                                      text-decoration: none;
                                    }
                                  }
                                `}
                                {...props}
                              />
                            ),
                          }}
                        >
                          <MDXRenderer>{faq.answer}</MDXRenderer>
                        </MDXProvider>
                      </li>
                    ))}
                  </Ul>
                </Box>
              </Box>
            </Container>
          </Box>
        </SiteLayout>
        {isFetchingBorrower && (
          <Box
            position="fixed"
            top={0}
            right={0}
            bottom={0}
            left={0}
            zIndex={5000}
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="rgba(255,255,255,0.9)"
          >
            <LoadingSpinner mr={4} /> One moment...
          </Box>
        )}
      </Layout>
    </>
  )
}

const pageQuery = graphql`
  query {
    file(relativePath: { eq: "frank-busch-g7uPG8w1XV8-unsplash-cropped.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mdx(frontmatter: { slug: { eq: "home" } }) {
      frontmatter {
        metaTitle
        metaDescription
        hero {
          headline
          blurb
          buttonText
          buttonLink
        }
        about {
          blurb
          disclaimer
          headline
        }
        support {
          headline
          buttonText
          buttonLink
        }
      }
    }
    faqs: mdx(frontmatter: { slug: { eq: "support" } }) {
      frontmatter {
        FAQs {
          question
          answer
        }
      }
    }
  }
`

export default Home
export { pageQuery }
